import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { UserSaveData } from '../../models/user-save-data';

import { UserSaveDto } from './dto/user-save-dto';

import { ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error-mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Mapper for user entities. */
@Injectable({
  providedIn: 'root',
})
export class UserSaveMapper implements
IMapperToDto<UserSaveDto, UserSaveData>,
ValidationErrorMapper<UserSaveDto, UserSaveData> {

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<UserSaveDto>,
  ): EntityValidationErrors<UserSaveData> {
    return {
      employeeId: extractErrorMessageByErrorKey(errorDto, 'employee_id'),
      firstName: extractErrorMessageByErrorKey(errorDto, 'first_name'),
      lastName: extractErrorMessageByErrorKey(errorDto, 'last_name'),
      email: extractErrorMessageByErrorKey(errorDto, 'email'),
      role: extractErrorMessageByErrorKey(errorDto, 'role'),
      title: extractErrorMessageByErrorKey(errorDto, 'title'),
      bio: extractErrorMessageByErrorKey(errorDto, 'bio'),
    };
  }

  /** @inheritdoc */
  public toDto(data: UserSaveData): UserSaveDto {
    return {
      employee_id: data.employeeId,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      title: data.title,
      role: data.role,
      bio: data.bio,
    };
  }
}
