import { AbstractControl, UntypedFormGroup, UntypedFormControl, ValidationErrors, ValidatorFn, Validators, UntypedFormArray, AsyncValidatorFn } from '@angular/forms';
import { catchError, combineLatest, filter, map, Observable, of, switchMap, take, timer } from 'rxjs';

import { AppError, AppValidationError } from '../models/app-error';
import { Company } from '../models/company';
import { User } from '../models/user';
import { ValidationErrorCode } from '../models/validation-error-code';
import { VerifyEmailExistenceData } from '../models/verify-email-existence-data';
import { DEFAULT_DEBOUNCE_TIME } from '../rxjs/listen-control-changes';

/** Verify email function type. */
export type VerifyEmailFn = (data: VerifyEmailExistenceData, userId?: number) => Observable<void>;

/** Extract values from form control. */
export type FormControlValueExtractor<T> = (control: AbstractControl) => T;

/** Unique value validator options. */
export interface UniqueValueOpts<T> {

  /** Form control value extractor. */
  readonly extractorFn?: FormControlValueExtractor<T>;

  /** Error message. */
  readonly message: string;
}

/** Custom validators. */
export namespace AppValidators {

  /**
   * Validator that requires the length of the control's value to be equal to the provided length.
   * @param length Length.
   * @param message Validation error message.
   */
  export function equalsLength(length: number, message = 'Length should be'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !hasValidLength(control.value)) {
        // don't validate empty values to allow optional controls
        // don't validate values without `length` property
        return null;
      }

      return control.value.length !== length ?
        { [ValidationErrorCode.EqualLength]: { requiredLength: length, message } } :
        null;
    };
  }

  /**
   * Form validator that sets the error of the passed validator for the child control,
   * When the condition function for conditional control is executed.
   * The validator is on the form, not on the control, as it is necessary to track changes in another control.
   * @param controlName The name of the control for which the error is detected from the validator.
   * @param validator For setting the error to control.
   * @param conditionalControlName The control that determines whether it need to set the validator or not.
   * @param conditionalFunction Takes a value from conditionalControl and returns true if a validator is needed.
   */
  export function conditionalValidator<T>(
    controlName: string,
    validator: ValidatorFn,
    conditionalControlName: string,
    conditionalFunction: (value: T) => boolean,
  ): ValidatorFn {
    return (formGroup => {
      const formControl = formGroup.get(controlName);
      const conditionalFormControl = formGroup.get(conditionalControlName);
      if (formControl === null || conditionalFormControl === null) {
        return null;
      }
      if (conditionalFunction(conditionalFormControl.value)) {
        const error = validator(formControl);
        formControl.setErrors(error);
      } else {
        formControl.setErrors(null);
      }
      return null;
    });
  }

  /**
   * Checks whether the current control matches another.
   * @param controlName Control name to check matching with.
   * @param controlTitle Control title to display for a user.
   */
  export function matchControl(controlName: string, controlTitle: string = controlName): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent && control.parent.get(controlName)?.value !== control.value) {
        return {
          [ValidationErrorCode.Match]: {
            controlName,
            controlTitle,
          },
        };
      }
      return null;
    };
  }

  /**
   * Unique email validator.
   * @param verifyEmail Verify email function.
   * @param existingUserId$ Existing user ID observable. In order not to mark the mail of this user as invalid.
   * @param companyId$ Company ID.
   */
  export function uniqueEmail(
    verifyEmail: VerifyEmailFn,
    existingUserId$?: Observable<User['id'] | null>,
    companyId$?: Observable<Company['id'] | null>,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => timer(DEFAULT_DEBOUNCE_TIME).pipe(
        filter(() => control.value !== ''),
        switchMap(() => combineLatest([
          existingUserId$ ?? of(undefined),
          companyId$ ?? of(undefined),
        ])),
        take(1),
        switchMap(([existingUserId, companyId]) => verifyEmail(
          { email: control.value, companyId: companyId ?? undefined },
          existingUserId ?? undefined,
        ).pipe(
          catchError((error: AppValidationError<VerifyEmailExistenceData>) =>
            of(buildCustomValidationError(typeof error.validationData.email === 'string' ? error.validationData.email : ''))),
          map(data => data ? data : null),
        )),
    );
  }

  /**
   * Checks form array for unique values by compare function.
   * @param options Unique value options.
   */
  export function uniqueValue<T>(options?: UniqueValueOpts<T>): ValidatorFn {

    const {
      extractorFn = (control: AbstractControl) => control.value,
      message = 'The value must be unique in the list',
    } = options ?? {};

    const composeUniqueValidationErrors = (values: unknown[]): ValidationErrors | null => {
      if (isUniqueValues(values)) {
        return null;
      }

      return {
        [ValidationErrorCode.AppError]: { message },
      };
    };

    return (formControl: AbstractControl): ValidationErrors | null => {

      if (formControl instanceof UntypedFormArray) {
        const plainValues = formControl.controls.map(control => extractorFn(control));
        return composeUniqueValidationErrors(plainValues);
      }

      if (formControl instanceof UntypedFormGroup) {
        const plainValues = Object.values(formControl.controls).map(control => extractorFn(control));
        return composeUniqueValidationErrors(plainValues);
      }

      throw new AppError('"uniqueValueInArray" validator is supposed to be used with a FormArray or FormGroup instance.');
    };
  }

  /**
   * Checks form group for unique values.
   * @param formControl Abstract control.
   */
  export function isNotSameValues(formControl: AbstractControl): ValidationErrors | null {

    /**
     * Get plain values.
     * Nothing if value in array is primitive.
     * And get value from key property if it is object.
     * @param rawValues Raw values.
     */
    function getPlainValues(rawValues: unknown[]): unknown[] {
      const values: unknown[] = [];

      rawValues.forEach(rawValue => {
        if (rawValue && typeof rawValue === 'object') {

          if (isKeyValueObject(rawValue)) {
            values.push(rawValue.key);
          } else {
            throw new AppError('Object must contain "key" property for use "isNotSameValues" validator with objects.');
          }

        } else {
          values.push(rawValue);
        }
      });

      return values;
    }

    if (formControl instanceof UntypedFormGroup) {
      const controls = Object.values(formControl.controls);
      const controlsValues = controls.map(control => control.value);
      const plainValues = getPlainValues(controlsValues);
      const isAllControlsTouched = controls.filter(control => (control.dirty || control.touched)).length === controls.length;
      const isControlsRequired = controls.filter(control => control.hasValidator(Validators.required)).length === controls.length;
      const emptyValues = controls.filter(control => !control.value);
      const uniqueValues = [...new Set(plainValues)];

      /** If it contains only empty values. */
      if (uniqueValues.length === 1 && plainValues[0] === '') {
        return null;
      }

      /** For cases where there are optional fields and only they match, then you can not throw an error. */
      const uniqueEmptyValueCount = 1;
      if ((uniqueValues.length - uniqueEmptyValueCount + emptyValues.length) === plainValues.length && !isControlsRequired) {
        return null;
      }

      if (uniqueValues.length < plainValues.length && isAllControlsTouched) {
        return {
          [ValidationErrorCode.AppError]: {
            message: 'The ability to select the same values is not possible',
          },
        };
      }

      return null;
    }
    throw new AppError('"isNotSameValues" validator is supposed to be used with a FormGroup instance.');
  }

  /**
   * Validate that expiry date is correct.
   * @param control Text input control.
   */
  export function isExpiryDateCorrect(control: AbstractControl): ValidationErrors | null {
    if (control instanceof UntypedFormControl) {
      const expiryMonthAndYear = control.value;

      if (expiryMonthAndYear.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/) !== null) {
        const [month, year] = expiryMonthAndYear.split('/');
        const now = new Date();
        const expiryYear = Number(`20${year}`);
        const expiryMonth = month - 1;
        const expiryDate = new Date().setFullYear(expiryYear, expiryMonth);

        if (now.getTime() > expiryDate) {
          return {
            [ValidationErrorCode.ExpirationDate]: {
              message: 'Invalid Expiry Date. Date should be greater than current date',
            },
          };
        }

        return null;
      }

      return {
        [ValidationErrorCode.ExpirationDate]: {
          message: 'Expiry Date should be MM/YY format',
        },
      };
    }
    throw new AppError('"isExpiryDateCorrect" validator is supposed to be used with a FormControl instance.');
  }

  /**
   * Validate that field doesn't contain only spaces.
   * @param control Abstract control.
   */
  export function noWhiteSpacesOnly(control: AbstractControl): ValidationErrors | null {
    if (control instanceof UntypedFormControl) {
      const controlValue = control.value;

      if (typeof controlValue !== 'string') {
        return null;
      }

      const isEmpty = controlValue.length === controlValue.trim().length;
      const isOnlyWhiteSpaces = (controlValue.trim().length === 0) && !isEmpty;

      if (isOnlyWhiteSpaces) {
        return {
          [ValidationErrorCode.NoWhiteSpacesOnly]: {},
        };
      }

      return null;
    }

    throw new AppError('"noWhiteSpacesOnly" validator is supposed to be used with a FormControl instance.');
  }

  /**
   * Validate url is correct.
   * @param control Abstract control.
   */
  export function url(control: AbstractControl): ValidationErrors | null {
    const URL_VALIDATOR_REGEX = /^(http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm;
    if (control instanceof UntypedFormControl) {
      const controlValue = control.value;

      if (controlValue) {
        if (!URL_VALIDATOR_REGEX.test(controlValue)) {
          return {
            [ValidationErrorCode.Url]: {},
          };
        }
        return null;
      }
      return null;
    }

    throw new AppError('"url" validator is supposed to be used with a FormControl instance.');
  }

  /**
   * Create validation error from a message.
   * @param message Message to create an error from.
   */
  export function buildCustomValidationError(message: string): ValidationErrors {
    return {
      [ValidationErrorCode.AppError]: {
        message,
      },
    };
  }

  /**
   * Validate integer number without symbols.
   * @param control Abstract control.
   */
  export function zeroOrPositiveInteger(control: AbstractControl): ValidationErrors | null {
    const INTEGER_NUMBER_PATTERN = /^[0-9]\d*$/;
    const isValid = INTEGER_NUMBER_PATTERN.test(control.value);
    const message = 'Must be an integer.';
    return isValid ? null : {
      [ValidationErrorCode.AppError]: { message },
    };
  }
}

/**
 * Type guard for object with key property.
 * @param object Object.
 */
function isKeyValueObject(object: KeyValueObject | unknown): object is KeyValueObject {
  return (object as KeyValueObject).key !== undefined;
}

/** Key value object with key property require. */
interface KeyValueObject {
  [key: string]: unknown;

  /** Value. */
  key: string;
}

/**
 * Array contains only unique values.
 * @param values Plain values.
 */
function isUniqueValues(values: unknown[]): boolean {
  const uniqueValues = [...new Set(values)];

  /** If it contains only empty values. */
  if (uniqueValues.length === 1 && values[0] === '') {
    return true;
  }

  if (uniqueValues.length < values.length) {
    return false;
  }

  return true;
}

/**
 * Checks whether the value has `length` property.
 * @param value Value.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasValidLength(value: any): boolean {
  return value != null && typeof value.length === 'number';
}

/**
 * Check if the object is a string or array before evaluating the length attribute.
 * This avoids falsely rejecting objects that contain a custom length attribute.
 * For example, the object {id: 1, length: 0, width: 0} should not be returned as empty.
 * @param value Value.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEmptyInputValue(value: any): boolean {
  return value == null ||
      ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
}
