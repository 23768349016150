import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';

import { LineItem, LineItemConstructorData } from '../../models/line-item';
import { LineItemSaveData } from '../../models/line-item-save-data';

import { DateMapper } from './date.mapper';

import { LineItemDto } from './dto/line-item-dto';
import { LineItemSaveDto } from './dto/line-item-save-dto';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error-mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Mapper for line item entities. */
@Injectable({
  providedIn: 'root',
})
export class LineItemMapper implements
IMapperFromDto<LineItemDto, LineItem>,
IMapperToDto<LineItemSaveDto, LineItemSaveData>,
ValidationErrorMapper<LineItemSaveDto, LineItemSaveData> {

  public constructor(
    private readonly dateMapper: DateMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: LineItemDto): LineItem {
    return new LineItem(this.mapConstructorData(data));
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<LineItemSaveDto>,
  ): EntityValidationErrors<LineItemSaveData> {
    return {
      name: extractErrorMessageByErrorKey(errorDto, 'name'),
      price: extractErrorMessageByErrorKey(errorDto, 'price'),
      description: extractErrorMessageByErrorKey(errorDto, 'description'),
    };
  }

  /** @inheritdoc */
  public toDto(data: LineItemSaveData): LineItemSaveDto {

    return {
      name: data.name,
      price: data.price.toString(),
      description: data.description,
      tax_option: data.taxType,
    };
  }

  /**
   * Map constructor data for a line item.
   * @param data Data to map.
   */
  private mapConstructorData(data: LineItemDto): LineItemConstructorData {
    return {
      id: data.id,
      name: data.name,
      price: Number(data.price),
      description: data.description,
      companyId: data.company,
      createdBy: data.created_by,
      updatedBy: data.updated_by,
      created: this.dateMapper.fromDto(data.created),
      modified: this.dateMapper.fromDto(data.modified),
      taxType: data.tax_option,
    };
  }

}
