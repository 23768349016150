import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { VerifyEmailExistenceData } from '../../models/verify-email-existence-data';

import { ValidationErrorDto } from './dto/validation-error-dto';
import { VerifyEmailExistenceDto } from './dto/verify-email-existence-dto';
import { IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error-mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Verify email existence mapper. */
@Injectable({ providedIn: 'root' })
export class VerifyEmailExistenceMapper implements
IMapperToDto<VerifyEmailExistenceDto, VerifyEmailExistenceData>,
ValidationErrorMapper<VerifyEmailExistenceDto, VerifyEmailExistenceData> {

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<VerifyEmailExistenceDto>,
  ): EntityValidationErrors<VerifyEmailExistenceData> {
    return {
      email: extractErrorMessageByErrorKey(errorDto, 'email') ??
        extractErrorMessageByErrorKey(errorDto, 'non_field_errors'),
    };
  }

  /** @inheritdoc */
  public toDto(data: VerifyEmailExistenceData): VerifyEmailExistenceDto {
    return {
      email: data.email,
      company: data.companyId ?? undefined,
    };
  }
}
