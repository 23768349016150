import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';

import { LineItemGroup, LineItemGroupConstructorData, LineItemGroupCreationData } from '../../models/line-item-group';

import { DateMapper } from './date.mapper';

import { LineItemGroupCreationDataDto, LineItemGroupDto } from './dto/line-item-group-dto';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { LineItemMapper } from './line-item.mapper';
import { IMapperFromDto, IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error-mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Mapper for line item group entities. */
@Injectable({
  providedIn: 'root',
})
export class LineItemGroupMapper implements
IMapperFromDto<LineItemGroupDto, LineItemGroup>,
IMapperToDto<LineItemGroupCreationDataDto, LineItemGroupCreationData>,
ValidationErrorMapper<LineItemGroupCreationDataDto, LineItemGroupCreationData> {

  public constructor(
    private readonly lineItemMapper: LineItemMapper,
    private readonly dateMapper: DateMapper,
  ) { }

  /** @inheritdoc */
  public toDto(data: LineItemGroupCreationData): LineItemGroupCreationDataDto {
    return {
      name: data.name,
      line_items: data.lineItemIds,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<LineItemGroupCreationDataDto>,
  ): EntityValidationErrors<LineItemGroupCreationData> {
    return {
      name: extractErrorMessageByErrorKey(errorDto, 'name'),
      lineItemIds: extractErrorMessageByErrorKey(errorDto, 'line_items'),
    };
  }

  /** @inheritdoc */
  public fromDto(data: LineItemGroupDto): LineItemGroup {
    return new LineItemGroup(this.mapConstructorData(data));
  }

  private mapConstructorData(data: LineItemGroupDto): LineItemGroupConstructorData {
    return {
      id: data.id,
      name: data.name,
      companyId: data.company,
      lineItems: data.line_items.map(lineItemDto => this.lineItemMapper.fromDto(lineItemDto)),
      createdDate: this.dateMapper.fromDto(data.created),
      modifiedDate: this.dateMapper.fromDto(data.modified),
    };
  }
}
