import { Injectable } from '@angular/core';
import { CompanyUpdateFormData } from '@protctc/common/core/forms/company-update-form';
import { SecondStepRegistrationFormData } from '@protctc/common/core/forms/second-step-registration-form';
import { EntityValidationErrors } from '@protctc/common/core/models/app-error';
import { Company, CompanyCreate, CompanySecondStepCreate, CompanyUpdate } from '@protctc/common/core/models/company/company';
import { assertFilesUploaded } from '@protctc/common/core/utils/assert-file-uploaded';

import {
  extractErrorMessageByErrorKey,
} from '@protctc/common/core/services/mappers/extract-error-message';

import { AddressMapper } from '../address/address.mapper';
import { DateMapper } from '../date.mapper';
import { CompanyCreateDto, CompanyDto, CompanySecondStepCreateDto, CompanyUpdateDto } from '../dto/company/company.dto';
import { ValidationErrorDto } from '../dto/validation-error-dto';
import { IMapperFromDto } from '../mappers';

import { BankAccountMapper } from './bank-account.mapper';
import { BusinessOwnerMapper } from './business-owner.mapper';
import { CompanyContactMapper } from './company-contact.mapper';
import { UploadedDocumentMapper } from './uploaded-document.mapper';
import { MerchantCategoryCodeMapper } from './merchant-category-code.mapper';
import { BusinessOwnerCreateMapper } from './business-owner-create.mapper';

/** Company mapper. */
@Injectable({
  providedIn: 'root',
})
export class CompanyMapper implements IMapperFromDto<CompanyDto, Company> {

  public constructor(
    private readonly dateMapper: DateMapper,
    private readonly addressMapper: AddressMapper,
    private readonly bankAccountMapper: BankAccountMapper,
    private readonly businessOwnerCreateMapper: BusinessOwnerCreateMapper,
    private readonly businessOwnerMapper: BusinessOwnerMapper,
    private readonly companyContactMapper: CompanyContactMapper,
    private readonly uploadedDocumentMapper: UploadedDocumentMapper,
    private readonly merchantCategoryCodeMapper: MerchantCategoryCodeMapper,
  ) { }

  /** @inheritdoc */
  public toCompanyCreateDto(data: CompanyCreate): CompanyCreateDto {
    return {
      name: data.name,
      owner_first_name: data.ownerFirstName,
      owner_last_name: data.ownerLastName,
      email: data.email,
      phone: data.phone,
      ...this.addressMapper.toDto(data.address),
    };
  }

  /** @inheritdoc */
  public validationErrorFromCompanyCreateDto(
    errorDto: ValidationErrorDto<CompanyCreateDto>,
  ): EntityValidationErrors<CompanyCreate> {
    return {
      ownerFirstName: extractErrorMessageByErrorKey(errorDto, 'owner_first_name'),
      ownerLastName: extractErrorMessageByErrorKey(errorDto, 'owner_last_name'),
      email: extractErrorMessageByErrorKey(errorDto, 'email'),
      phone: extractErrorMessageByErrorKey(errorDto, 'phone'),
      name: extractErrorMessageByErrorKey(errorDto, 'name'),
      address: {
        line1Address: extractErrorMessageByErrorKey(errorDto, 'address_1'),
        line2Address: extractErrorMessageByErrorKey(errorDto, 'address_2'),
        city: extractErrorMessageByErrorKey(errorDto, 'address_city'),
        state: extractErrorMessageByErrorKey(errorDto, 'address_state'),
        zip: extractErrorMessageByErrorKey(errorDto, 'address_zip'),
      },
    };
  }

  /** @inheritdoc */
  public toCompanyUpdateDto(data: CompanyUpdate): CompanyUpdateDto {
    return {
      name: data.name,
      owner_first_name: data.ownerFirstName,
      owner_last_name: data.ownerLastName,
      email: data.email,
      phone: data.phone,
      ...this.addressMapper.toDto(data.address),
      dba: data.dba,
      ein: data.ein,
      open_date: this.dateMapper.toDateOnlyStringDto(data.openDate),
      refund_policy: data.refundPolicy.value,
      business_service: data.businessService,
      url: data.url,
      ticket_average: data.ticketAverage.toString(),
      annual_cc_sales: data.annualCCSales.toString(),
      ...this.bankAccountMapper.toDto(data.bankAccount),
      seasonal_status: data.seasonalStatus,
      mcc: data.merchantCategoryCode.id,
      company_type: data.companyType.value,
      fax: data.fax,
      business_owners: data.businessOwners.map(owner => this.businessOwnerMapper.toDto(owner)),
      is_public: data.isPublic,
    };
  }

  /** @inheritdoc */
  public validationErrorFromCompanyUpdateDto(
    errorDto: ValidationErrorDto<CompanyUpdateDto>,
  ): EntityValidationErrors<CompanyUpdateFormData> {
    return {
      creation: {
        ownerFirstName: extractErrorMessageByErrorKey(errorDto, 'owner_first_name'),
        ownerLastName: extractErrorMessageByErrorKey(errorDto, 'owner_last_name'),
        email: extractErrorMessageByErrorKey(errorDto, 'email'),
        phone: extractErrorMessageByErrorKey(errorDto, 'phone'),
        name: extractErrorMessageByErrorKey(errorDto, 'name'),
        address: {
          line1Address: extractErrorMessageByErrorKey(errorDto, 'address_1'),
          line2Address: extractErrorMessageByErrorKey(errorDto, 'address_2'),
          city: extractErrorMessageByErrorKey(errorDto, 'address_city'),
          state: extractErrorMessageByErrorKey(errorDto, 'address_state'),
          zip: extractErrorMessageByErrorKey(errorDto, 'address_zip'),
        },
      },
      business: {
        dba: extractErrorMessageByErrorKey(errorDto, 'dba'),
        ein: extractErrorMessageByErrorKey(errorDto, 'ein'),
        openDate: extractErrorMessageByErrorKey(errorDto, 'open_date'),
        refundPolicy: extractErrorMessageByErrorKey(errorDto, 'refund_policy'),
        businessService: extractErrorMessageByErrorKey(errorDto, 'business_service'),
        url: extractErrorMessageByErrorKey(errorDto, 'url'),
        ticketAverage: extractErrorMessageByErrorKey(errorDto, 'ticket_average'),
        annualCCSales: extractErrorMessageByErrorKey(errorDto, 'annual_cc_sales'),
        seasonalStatus: extractErrorMessageByErrorKey(errorDto, 'seasonal_status'),
        companyType: extractErrorMessageByErrorKey(errorDto, 'company_type'),
        merchantCategoryCode: extractErrorMessageByErrorKey(errorDto, 'mcc'),
        fax: extractErrorMessageByErrorKey(errorDto, 'fax'),
      },
      bank: {
        name: extractErrorMessageByErrorKey(errorDto, 'bank_account_name'),
        accountNumber: extractErrorMessageByErrorKey(errorDto, 'bank_account_number'),
        routingNumber: extractErrorMessageByErrorKey(errorDto, 'bank_routing_number'),
      },
      businessOwners: extractErrorMessageByErrorKey(errorDto, 'business_owners'),
      formError: extractErrorMessageByErrorKey(errorDto, 'non_field_errors'),
    };
  }

  /** @inheritdoc */
  public validationErrorFromCompanySecondStepCreateDto(
    errorDto: ValidationErrorDto<CompanySecondStepCreateDto>,
  ): EntityValidationErrors<SecondStepRegistrationFormData> {
    const nonFieldErrorsMessage = extractErrorMessageByErrorKey(errorDto, 'non_field_errors');
    return {
      business: {
        dba: extractErrorMessageByErrorKey(errorDto, 'dba'),
        ein: extractErrorMessageByErrorKey(errorDto, 'ein'),
        openDate: extractErrorMessageByErrorKey(errorDto, 'open_date'),
        refundPolicy: extractErrorMessageByErrorKey(errorDto, 'refund_policy'),
        businessService: extractErrorMessageByErrorKey(errorDto, 'business_service'),
        url: extractErrorMessageByErrorKey(errorDto, 'url'),
        ticketAverage: extractErrorMessageByErrorKey(errorDto, 'ticket_average'),
        annualCCSales: extractErrorMessageByErrorKey(errorDto, 'annual_cc_sales'),
        seasonalStatus: extractErrorMessageByErrorKey(errorDto, 'seasonal_status'),
        companyType: extractErrorMessageByErrorKey(errorDto, 'company_type'),
        merchantCategoryCode: extractErrorMessageByErrorKey(errorDto, 'mcc'),
        fax: extractErrorMessageByErrorKey(errorDto, 'fax'),
      },
      bank: {
        name: extractErrorMessageByErrorKey(errorDto, 'bank_account_name'),
        accountNumber: extractErrorMessageByErrorKey(errorDto, 'bank_account_number'),
        routingNumber: extractErrorMessageByErrorKey(errorDto, 'bank_routing_number'),
      },
      businessOwners: extractErrorMessageByErrorKey(errorDto, 'business_owners'),
      nonFieldErrors: Array.isArray(nonFieldErrorsMessage) ? nonFieldErrorsMessage.join(';') : nonFieldErrorsMessage,
    };
  }

  /** @inheritdoc */
  public toCompanySecondStepCreateDto(data: CompanySecondStepCreate, token: string): CompanySecondStepCreateDto {
    assertFilesUploaded(data.voidCheck);
    return {
      dba: data.dba,
      ein: data.ein,
      open_date: this.dateMapper.toDateOnlyStringDto(data.openDate),
      refund_policy: data.refundPolicy.value,
      business_service: data.businessService,
      url: data.url,
      ticket_average: data.ticketAverage.toString(),
      annual_cc_sales: data.annualCCSales.toString(),
      ...this.bankAccountMapper.toDto(data.bankAccount),
      seasonal_status: data.seasonalStatus,
      company_type: data.companyType.value,
      fax: data.fax ?? undefined,
      mcc: data.merchantCategoryCode.id,
      business_owners: data.businessOwners.map(owner => this.businessOwnerCreateMapper.toDto(owner)),
      is_public: data.isPublic,
      documents: data.voidCheck.map(document => this.uploadedDocumentMapper.toDto(document)),
      token,
    };
  }

  /** @inheritdoc */
  public fromDto(data: CompanyDto): Company {
    return {
      id: data.id,
      name: data.name,
      dba: data.dba,
      ein: data.ein ?? '',
      merchantCategoryCode: data.mcc_info ?
        this.merchantCategoryCodeMapper.fromDto(data.mcc_info) :
        null,
      openDate: data.open_date ? this.dateMapper.fromDto(data.open_date) : null,
      refundPolicy: { key: data.refund_policy_value, value: data.refund_policy },
      businessService: data.business_service,
      email: data.email,
      phone: data.phone,
      fax: data.fax ?? '',
      url: data.url ?? '',
      address: this.addressMapper.fromDto(data),
      annualCCSales: data.annual_cc_sales ? Number(data.annual_cc_sales) : 0,
      ticketAverage: Number(data.ticket_average),
      bankAccount: this.bankAccountMapper.fromDto(data),
      contactInfo: data.contact_info ? this.companyContactMapper.fromDto(data.contact_info) : null,
      deactivatedAt: data.deactivated_at ? this.dateMapper.fromDto(data.deactivated_at) : null,
      seasonalStatus: data.seasonal_status,
      companyType: { key: data.company_type_value, value: data.company_type },
      ownerFirstName: data.owner_first_name ?? '',
      ownerLastName: data.owner_last_name ?? '',
      businessOwners: data.business_owners.map(owner => this.businessOwnerMapper.fromDto(owner)),
      merchantStatus: data.merchant_status ?? '',
      isPublic: data.is_public,
      isRegisteredInPayrix: data.is_sync_to_payrix,
    };
  }
}
