import { Injectable, inject } from '@angular/core';
import { RecurringSchedule, RecurringScheduleCreation } from '@protctc/common/core/models/recurring-schedule/recurring-schedule';
import { EntityValidationErrors } from '@protctc/common/core/models/app-error';

import { InvoiceCustomerCreationDto } from '@protctc/common/core/services/mappers/dto/customer-dto';

import { RecurringScheduleCreationDto, RecurringScheduleDto } from '../dto/recurring-schedule/recurring-schedule.dto';
import { InvoiceCustomerMapper } from '../invoice-customer.mapper';
import { InvoiceLineItemMapper } from '../invoice-line-item.mapper';
import { InvoiceDeliveryMapper } from '../invoice-delivery.mapper';
import { DeliveryPeriodMapper } from '../delivery-period.mapper';
import { DateMapper } from '../date.mapper';
import { TimezoneMapper } from '../timezone/timezone.mapper';
import { ValidationErrorDto } from '../dto/validation-error-dto';

import { extractErrorMessageByErrorKey, getChildErrorDto } from '../extract-error-message';

import { RecurringScheduleModeMapper } from './recurring-schedule-mode.mapper';

/** Recurring schedule mapper. */
@Injectable({
  providedIn: 'root',
})
export class RecurringScheduleMapper {

  private readonly recurringScheduleModeMapper = inject(RecurringScheduleModeMapper);

  private readonly invoiceCustomerMapper = inject(InvoiceCustomerMapper);

  private readonly invoiceLineItemMapper = inject(InvoiceLineItemMapper);

  private readonly invoiceDeliveryMapper = inject(InvoiceDeliveryMapper);

  private readonly deliveryPeriodMapper = inject(DeliveryPeriodMapper);

  private readonly dateMapper = inject(DateMapper);

  private readonly timezoneMapper = inject(TimezoneMapper);

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<RecurringScheduleCreationDto>,
  ): EntityValidationErrors<RecurringScheduleCreation> {

    return {
      fieldUserId: extractErrorMessageByErrorKey(errorDto, 'field_user'),
      customer: this.invoiceCustomerMapper.validationErrorFromDto(
        getChildErrorDto(errorDto, 'customer_info') as ValidationErrorDto<InvoiceCustomerCreationDto>,
      ),
      lineItems: extractErrorMessageByErrorKey(errorDto, 'line_items'),
      disclaimer: extractErrorMessageByErrorKey(errorDto, 'disclaimer'),
      note: extractErrorMessageByErrorKey(errorDto, 'note'),
    };
  }

  /** @inheritdoc */
  public fromDto(data: RecurringScheduleDto): RecurringSchedule {
    return {
      id: data.id,
      createdDate: this.dateMapper.fromDto(data.created),
      startDate: this.dateMapper.fromDto(data.start_date),
      recurringScheduleMode: this.recurringScheduleModeMapper.fromDto(data.recurring_schedule),
      nextGenerateDate: data.next_generate_date ? this.dateMapper.fromDto(data.next_generate_date) : null,
    };
  }

  /** @inheritdoc */
  public toCreationDto(data: RecurringScheduleCreation): RecurringScheduleCreationDto {
    return {
      field_user: data.fieldUserId,
      customer: data.customerId,
      customer_info: this.invoiceCustomerMapper.toDto(data.customer),
      line_items: data.lineItems.map(lineItem => this.invoiceLineItemMapper.toDto(lineItem)),
      disclaimer: data.disclaimer,
      note: data.note,
      invoice_delivery: this.invoiceDeliveryMapper.toDto(data.deliveryMethod),
      delivery_period: this.deliveryPeriodMapper.toDto(data.deliveryPeriod),
      recurring_schedule: this.recurringScheduleModeMapper.toDto(data.recurringSchedule),
      weekday: data.weekday ?? undefined,
      day_of_month: data.dayOfMonth ?? undefined,
      day_of_quarter: data.dayOfQuarter ?? undefined,
      month_of_year: data.monthOfYear ?? undefined,
      start_date: this.dateMapper.toDateOnlyStringDto(data.startDate),
      number_of_invoice: data.totalInvoices ?? undefined,
      timezone: this.timezoneMapper.toDto(data.timezone),
      sent_time: this.dateMapper.toDateTimeStringDto(data.sentTime),
      first_additional_field_value: data.firstAdditionalFieldValue,
      second_additional_field_value: data.secondAdditionalFieldValue,
      third_additional_field_value: data.thirdAdditionalFieldValue,
    };
  }
}
