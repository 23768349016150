import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { InvoiceTemplate } from '../../models/invoice-template';

import { InvoiceTemplateSaveData } from '../../models/invoice-template-save-data';
import { assertFilesUploaded, assertFileUploaded } from '../../utils/assert-file-uploaded';

import { InvoiceTemplateDto } from './dto/invoice-template-dto';

import { InvoiceTemplateSaveDto } from './dto/invoice-template-save-dto';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { InvoiceNoteMapper } from './invoice-note.mapper';
import { IMapperFromDto, IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error-mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Mapper for invoice template entity. */
@Injectable({
  providedIn: 'root',
})
export class InvoiceTemplateMapper implements
IMapperToDto<InvoiceTemplateSaveDto, InvoiceTemplateSaveData>,
IMapperFromDto<InvoiceTemplateDto, InvoiceTemplate>,
ValidationErrorMapper<InvoiceTemplateSaveDto, InvoiceTemplateSaveData> {

  public constructor(
    private readonly invoiceNoteMapper: InvoiceNoteMapper,
  ) { }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<InvoiceTemplateSaveDto>,
  ): EntityValidationErrors<InvoiceTemplateSaveData> {
    return {
      logoImage: extractErrorMessageByErrorKey(errorDto, 'logo'),
      brandingColor: extractErrorMessageByErrorKey(errorDto, 'branding_color'),
      headerFirstLine: extractErrorMessageByErrorKey(errorDto, 'header_first_line'),
      headerSecondLine: extractErrorMessageByErrorKey(errorDto, 'header_second_line'),
      headerThirdLine: extractErrorMessageByErrorKey(errorDto, 'header_third_line'),
      firstAdditionalFieldTitle: extractErrorMessageByErrorKey(errorDto, 'first_additional_field_title'),
      secondAdditionalFieldTitle: extractErrorMessageByErrorKey(errorDto, 'second_additional_field_title'),
      thirdAdditionalFieldTitle: extractErrorMessageByErrorKey(errorDto, 'third_additional_field_title'),
      workDetailFirstCol: extractErrorMessageByErrorKey(errorDto, 'work_detail_first'),
      workDetailSecondCol: extractErrorMessageByErrorKey(errorDto, 'work_detail_second'),
      workDetailThirdCol: extractErrorMessageByErrorKey(errorDto, 'work_detail_third'),
      workDetailFourthCol: extractErrorMessageByErrorKey(errorDto, 'work_detail_fourth'),
      disclaimer: extractErrorMessageByErrorKey(errorDto, 'disclaimer'),
      footerFirstLine: extractErrorMessageByErrorKey(errorDto, 'footer_first_line'),
      footerSecondLine: extractErrorMessageByErrorKey(errorDto, 'footer_second_line'),
      footerThirdLine: extractErrorMessageByErrorKey(errorDto, 'footer_third_line'),
      footerFourthLine: extractErrorMessageByErrorKey(errorDto, 'footer_fourth_line'),
      logoPosition: extractErrorMessageByErrorKey(errorDto, 'logo_position'),
      attachments: extractErrorMessageByErrorKey(errorDto, 'notes'),
      isOverdueNotificationsEnable: extractErrorMessageByErrorKey(errorDto, 'enable_overdue_notification'),
      overdueNotificationsInterval: extractErrorMessageByErrorKey(errorDto, 'overdue_notification_interval'),
    };
  }

  /** @inheritdoc */
  public toDto(data: InvoiceTemplateSaveData): InvoiceTemplateSaveDto {
    assertFileUploaded(data.logoImage);
    assertFilesUploaded(data.attachments);

    return {
      logo: data.logoImage,
      branding_color: data.brandingColor,
      header_first_line: data.headerFirstLine,
      header_second_line: data.headerSecondLine,
      header_third_line: data.headerThirdLine,
      first_additional_field_title: data.firstAdditionalFieldTitle,
      second_additional_field_title: data.secondAdditionalFieldTitle,
      third_additional_field_title: data.thirdAdditionalFieldTitle,
      work_detail_first: data.workDetailFirstCol,
      work_detail_second: data.workDetailSecondCol,
      work_detail_third: data.workDetailThirdCol,
      work_detail_fourth: data.workDetailFourthCol,
      disclaimer: data.disclaimer,
      footer_first_line: data.footerFirstLine,
      footer_second_line: data.footerSecondLine,
      footer_third_line: data.footerThirdLine,
      footer_fourth_line: data.footerFourthLine,
      logo_position: data.logoPosition,
      notes: data.attachments.map(this.invoiceNoteMapper.toDto),
      enable_overdue_notification: data.isOverdueNotificationsEnable,
      overdue_notification_interval: data.overdueNotificationsInterval,
      mask_prefix: data.invoiceMaskPrefix,
      starting_number: data.invoiceMaskStartNumber,
    };
  }

  /** @inheritdoc */
  public fromDto(data: InvoiceTemplateDto): InvoiceTemplate {
    return {
      id: data.id,
      logo: data.logo,
      brandingColor: data.branding_color,
      headerFirstLine: data.header_first_line,
      headerSecondLine: data.header_second_line,
      headerThirdLine: data.header_third_line,
      firstAdditionalFieldTitle: data.first_additional_field_title,
      secondAdditionalFieldTitle: data.second_additional_field_title,
      thirdAdditionalFieldTitle: data.third_additional_field_title,
      workDetailFirstColKey: data.work_detail_first_col,
      workDetailSecondColKey: data.work_detail_second_col,
      workDetailThirdColKey: data.work_detail_third_col,
      workDetailFourthColKey: data.work_detail_fourth_col,
      workDetailFirstColValue: data.work_detail_first,
      workDetailSecondColValue: data.work_detail_second,
      workDetailThirdColValue: data.work_detail_third,
      workDetailFourthColValue: data.work_detail_fourth,
      disclaimer: data.disclaimer,
      footerFirstLine: data.footer_first_line,
      footerSecondLine: data.footer_second_line,
      footerThirdLine: data.footer_third_line,
      footerFourthLine: data.footer_fourth_line,
      logoPosition: data.logo_position,
      notes: data.notes.map(this.invoiceNoteMapper.fromDto),
      isOverdueNotificationsEnable: data.enable_overdue_notification,
      overdueNotificationsInterval: data.overdue_notification_interval,
      companyId: data.company,
      invoiceMaskPrefix: data.mask_prefix,
      invoiceMaskStartNumber: data.starting_number,
      invoiceCurrentNumber: data.increment_number,
    };
  }
}
