import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { PasswordResetConfirmation } from '../../models/password-reset-confirmation';

import { PasswordResetConfirmationDto } from './dto/password-reset-confirmation-dto';

import { ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperToDtoWithErrors } from './mappers';
import { TokenMapper } from './token.mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Mapper for reset password data. */
@Injectable({
  providedIn: 'root',
})
export class ResetPasswordConfirmationMapper
implements IMapperToDtoWithErrors<PasswordResetConfirmationDto, PasswordResetConfirmation> {

  public constructor(
    private readonly tokenMapper: TokenMapper,
  ) { }

  /** @inheritdoc */
  public toDto(data: PasswordResetConfirmation): PasswordResetConfirmationDto {
    return {
      password: data.password,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      password_confirm: data.passwordConfirmation,
      ...this.tokenMapper.toDto(data),
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<PasswordResetConfirmationDto>,
  ): EntityValidationErrors<PasswordResetConfirmation> {
    return {
      password: extractErrorMessageByErrorKey(errorDto, 'password') ?? extractErrorMessageByErrorKey(errorDto, 'non_field_errors'),
      passwordConfirmation: extractErrorMessageByErrorKey(errorDto, 'password_confirm'),
    };
  }

}
