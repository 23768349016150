import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { InvoiceCustomer, InvoiceCustomerCreationData } from '../../models/customer';

import { InvoiceCustomerCreationDto, InvoiceCustomerDto } from './dto/customer-dto';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error-mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Mapper for customer entities. */
@Injectable({
  providedIn: 'root',
})
export class InvoiceCustomerMapper
implements IMapperFromDto<InvoiceCustomerDto, InvoiceCustomer>,
IMapperToDto<InvoiceCustomerCreationDto, InvoiceCustomerCreationData>,
ValidationErrorMapper<InvoiceCustomerCreationDto, InvoiceCustomerCreationData> {

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<InvoiceCustomerCreationDto>,
  ): EntityValidationErrors<InvoiceCustomerCreationData> {
    return {
      firstName: extractErrorMessageByErrorKey(errorDto, 'first_name'),
      lastName: extractErrorMessageByErrorKey(errorDto, 'last_name'),
      email: extractErrorMessageByErrorKey(errorDto, 'email'),
      phone: extractErrorMessageByErrorKey(errorDto, 'phone'),
      address: {
        line1Address: extractErrorMessageByErrorKey(errorDto, 'address'),
        city: extractErrorMessageByErrorKey(errorDto, 'address_city'),
        state: extractErrorMessageByErrorKey(errorDto, 'address_state'),
        zip: extractErrorMessageByErrorKey(errorDto, 'address_zip'),
      },
      companyName: extractErrorMessageByErrorKey(errorDto, 'company_name'),
      isActive: extractErrorMessageByErrorKey(errorDto, 'is_active'),
      firstAdditionalField: extractErrorMessageByErrorKey(errorDto, 'additional_field_1'),
      secondAdditionalField: extractErrorMessageByErrorKey(errorDto, 'additional_field_2'),
      thirdAdditionalField: extractErrorMessageByErrorKey(errorDto, 'additional_field_3'),
    };
  }

  /** @inheritdoc */
  public toDto(data: InvoiceCustomerCreationData): InvoiceCustomerCreationDto {
    return {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phone,
      address: data.address.line1Address,
      address_city: data.address.city,
      address_state: data.address.state,
      address_zip: data.address.zip,
      additional_field_1: data.firstAdditionalField,
      additional_field_2: data.secondAdditionalField,
      additional_field_3: data.thirdAdditionalField,
      company_name: data.companyName,
      is_active: data.isActive,
    };
  }

  /** @inheritdoc */
  public fromDto(data: InvoiceCustomerDto): InvoiceCustomer {
    return {
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      phone: data.phone,
      address: {
        line1Address: data.address,
        line2Address: '',
        city: data.address_city,
        state: data.address_state,
        zip: data.address_zip,
      },
      firstAdditionalField: data.additional_field_1 ?? '',
      secondAdditionalField: data.additional_field_2 ?? '',
      thirdAdditionalField: data.additional_field_3 ?? '',
      companyName: data.company_name,
      isActive: data.is_active ?? false,
      hasInvoices: data.has_invoices ?? false,
    };
  }
}
